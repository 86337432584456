<!-- *NAVBAR* starts -->
<app-navbar (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-navbar>
<!-- *NAVBAR* ends -->

<div (click)="onWrapperClick();" (window:resize)="onResize($event)" [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty}">


  <!-- *SIDE* Menu starts -->
  <div (mouseenter)="sidebarMouseenter($event)" (mouseleave)="sidebarMouseleave($event)"
       *ngIf="menuPosition === 'Side' || displayOverlayMenu"
       [attr.data-background-color]="config?.layout.variant === 'Transparent' ? 'black': bgColor"
       [attr.data-image]="bgImage"
       [ngClass]="{'main-menu': menuPosition === 'Side' || displayOverlayMenu, 'menu-fixed': menuPosition === 'Side' || displayOverlayMenu }"
       appSidebar
       class="app-sidebar"
       data-active-color="white">
    <app-sidebar></app-sidebar>
    <div *ngIf="config?.layout.sidebar.backgroundImage" [ngStyle]="{'background-image': 'url(' + bgImage + ')'}"
         class="sidebar-background"></div>
  </div>
  <!-- *SIDE* Menu ends -->

  <div class="main-panel" style="float: none">
    <div class="main-content">
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- FOOTER -->
    <app-footer [textClass]="'text-muted'"></app-footer>
    <!-- Scroll to top button -->
    <button (click)="scrollToTop()" *ngIf="isScrollTopVisible" class="btn btn-primary scroll-top round" type="button"><i
      class="ft-arrow-up"></i></button>
  </div>

  <!-- *NOTIFICATION-SIDEBAR* starts -->
  <app-notification-sidebar></app-notification-sidebar>

  <!-- *NOTIFICATION-SIDEBAR* ends -->

  <!-- *CUSTOMIZER* starts -- Uncomment below code if you want to use customizer in your project -->
  <!--<app-customizer></app-customizer>-->
  <!-- *CUSTOMIZER* ends -->
  <app-off-canvas>toto</app-off-canvas>

  <div (click)="onOutsideClick($event);"
       [ngClass]="{'d-none': displayOverlayMenu && hideSidebar && !overlayContent , 'd-block': displayOverlayMenu && !hideSidebar && overlayContent && innerWidth < 1200}"
       class="sidenav-overlay"></div>
  <div class="drag-target"></div>
</div>
