import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  name: 'prod',
  logLevel: NgxLoggerLevel.INFO,
  version: require('../../package.json').version,
  urlApi: '/gescots-api',

  /** CONFIGURATION */
  /** keycloak  */
  keycloakServer: 'https://keycloak.piti.pf/',
  keycloakRealm: 'Gescots',
  keycloakClientId: 'gescots-client',
  firebaseConfig: {
    apiKey: 'AIzaSyDDQ4AQ9DUdQyTP8UcijLTG6WRXkG0a2Vk',
    authDomain: 'gescots-cfcb1.firebaseapp.com',
    projectId: 'gescots-cfcb1',
    storageBucket: 'gescots-cfcb1.appspot.com',
    messagingSenderId: '424891350419',
    appId: '1:424891350419:web:e264b47527f2d36dd47c66',
    vapidKey: 'BB44b_98QFP1PofLpSIU-bwUiYEWxRHG6go3gYE1DsRvasFNjkkm7XpoT7RDWSqesQkeGdCkAmLP63eVR7YFPkE',
  },
};
