import { HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

import { Router } from '@angular/router';

import swal from 'sweetalert2';
import { EMPTY, Observable, throwError } from 'rxjs';
import { Url } from '../classes/url';
import { ErrorHandler } from '../classes/error-handler';
import { Injectable } from '@angular/core';

export interface IErreurService {
  handleError(error: any): Observable<never>;

  handle400(): Observable<never>;

  handle401(): Observable<never>;

  handle403(error: any): Observable<never>;

  handle404Default(err: HttpErrorResponse): Observable<never>;

  handle424(err: HttpErrorResponse): Observable<never>;

  handle500(): Observable<never>;

  handleHttpError(error: any, option?: ErrorHandler): Observable<never>;

  handleDefaultHttp(): Observable<never>;

  handleTimeout(): Observable<never>;

  handleDefault(): Observable<never>;

  resolverErreur(s: string): Observable<never>;
}

@Injectable({
  providedIn: 'root',
})

/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
export class ErreurService implements IErreurService {
  private readonly DEFAULT_ERREUR = 'Une erreur est survenue dans l\'application';
  private readonly MESSAGE_ERR_CLIENT = 'Une erreur client est survenue';
  private readonly TIMEOUT = 'timeout';

  constructor(private readonly logger: NGXLogger, public readonly router: Router) {
  }

  /**
   * Gestion des erreurs
   *
   * @param error - l'erreur
   * @param option - un errorHandler
   */
  handleError(error: any, option?: ErrorHandler): Observable<never> {
    this.logger.error('Gestion de l\'erreur', error);
    if (error instanceof HttpErrorResponse) {
      return this.handleHttpError(error, option);
    } else if (error.name === 'TimeoutError') {
      return option?.handleTimeout ? option.handleTimeout(error) : this.handleTimeout();
    } else {
      return option?.handleDefault ? option.handleDefault(error) : this.handleDefault();
    }
  }

  /** Gestion de l'erreur 400 */
  handle400(): Observable<never> {
    return throwError(() => new Error(this.MESSAGE_ERR_CLIENT));
  }

  /** Gestion de l'erreur 401 */
  handle401(): Observable<never> {
    return this.handleDefaultHttp();
  }

  /** Gestion de l'erreur 403 */
  public handle403(error: HttpErrorResponse): Observable<never> {
    this.router.navigate(['/', Url.ERREUR_BASE], {
      state: {
        erreur: error.error.message ?? '403: Vous n\'avez pas le droit d\'accéder à cette fonctionnalité',
      },
    });
    return throwError(() => null);
  }

  /** Gestion de l'erreur 404 */
  handle404Default(error: HttpErrorResponse): Observable<never> {
    this.router.navigate(['/', Url.ERREUR_BASE], {
      state: { erreur: error.error.message ?? '404: web service introuvable sur le serveur' },
    });
    return throwError(() => null);
  }

  /** Gestion de l'erreur 409 */
  handle409(error: HttpErrorResponse): Observable<never> {
    return throwError(() => new Error(error.error.message, { cause: 409 }));

  }

  /** Gestion de l'erreur 424 */
  handle424(error: HttpErrorResponse): Observable<never> {
    swal.fire('Erreur', error.error.message, 'error');
    return EMPTY;
  }

  /** Gestion de l'erreur 500 */
  handle500(): Observable<never> {
    return throwError(() => new Error(this.DEFAULT_ERREUR));
  }

  /**
   * Gestion des erreurs http
   *
   * @param error - l'erreur
   * @param option - un errorHandler
   */
  handleHttpError(error: any, option?: ErrorHandler): Observable<never> {
    switch (error.status) {
      case 0:
        return throwError(() => new Error('Impossible de se connecter au serveur'));
      case 400: // donnee en entree non conforme au schema
        return option?.handle400 ? option.handle400(error) : this.handle400();
      case 401:
        return option?.handle401 ? option.handle401(error) : this.handle401();
      case 403: // accès refuse
        return option?.handle403 ? option.handle403(error) : this.handle403(error);
      case 404:
        return option?.handle404 ? option.handle404(error) : this.handle404Default(error);
      case 424:
        return option?.handle424 ? option.handle424(error) : this.handle424(error);
      case 409:
        return option?.handle409 ? option.handle409(error) : this.handle409(error);
      case 500:
        return option?.handle500 ? option.handle500(error) : this.handle500();
      default:
        return option?.handleDefaultHttp ? option.handleDefaultHttp(error) : this.handleDefaultHttp();
    }
  }

  /** Gestion de l'erreur http par default */
  handleDefaultHttp(): Observable<never> {
    return this.handleDefault();
  }

  /** Gestion du timeout */
  handleTimeout(): Observable<never> {
    return throwError(() => new Error(this.TIMEOUT));
  }

  /** Gestion de l'erreur pas default */
  handleDefault(): Observable<never> {
    return throwError(() => new Error(this.DEFAULT_ERREUR));
  }

  /**
   * Erreur de chargement de la resource par le resolver.
   *
   * @param messsageErreur message d'erreur à afficher
   */
  resolverErreur(messsageErreur: string): Observable<never> {
    this.router.navigate(['/', Url.ERREUR_BASE], {
      state: {
        erreur: messsageErreur,
      },
    });
    return throwError(() => null);
  }
}
