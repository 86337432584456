import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EnumDepartement } from '../../api/model/dossier/devis/enumDepartement';
import { EnumStatutDossier } from '../../api/model/dossier/enumStatutDossier';
import { EnumTypeDemande } from '../../api/model/dossier/devis/enumTypeDemande';
import { TransporteurLight } from '../../api/model/contacts/transporteurLight';
import { Operateur } from '../../api/model/contacts/operateur';
import { IRechercheDossier } from '../../modules/dossiers/liste-dossier/liste-dossier.component';
import { EnumStatutDevis } from '../../api/model/dossier/devis/enumStatutDevis';
import { IRechercheDevis } from '../../modules/dossiers/liste-devis/liste-devis.component';
import { IRechercheClient } from '../../modules/contacts/liste-clients/liste-clients.component';
import { IRechercheAgent } from '../../modules/contacts/liste-agents/liste-agents.component';
import { IRechercheDeclarant } from '../../modules/contacts/liste-declarants/liste-declarants.component';
import { EnumTypeTransporteur } from '../../api/model/contacts/enumTypeTransporteur';
import { IRechercheTransporteur } from '../../modules/contacts/liste-transporteurs/liste-transporteurs.component';
import { EnumCategorieFacturation } from '../../api/model/params/enumCategorieFacturation';
import { IRechercheLigne } from '../../modules/params/liste-lignes-facturation/liste-lignes-facturation.component';
import { EnumEtatVoyage } from '../../api/model/dossier/enumEtatVoyage';
import { IRechercheDemandeDevis } from '../../modules/dossiers/liste-demande-devis-tsn/liste-demande-devis-tsn.component';
import { EnumTypeEvenement } from '../../api/model/notification/enumTypeEvenement';
import { IFormRecherhceEvenement } from '../../modules/notifications/liste-evenements/liste-evenement.component';
import { IRechercheDemandeDossier } from '../../modules/dossiers/liste-demande-dossier/liste-demande-dossier.component';
import { EnumStatutDemandeDossier } from '../../api/model/dossier/enumStatutDemandeDossier';


/**
 * Service utils pour gérer les lignes de facturation ( création, modification, suprression, actualisation des totaux, évaluation des montants, la totale ).
 */


@Injectable({
  providedIn: 'root',
})
export class FormGroupPersistance {

  private readonly cache: Map<FormGroupToPersist, FormGroup> = new Map<FormGroupToPersist, FormGroup>();

  constructor() {
  }

  getFormGroup(type: FormGroupToPersist): FormGroup {
    let formGroup: FormGroup | undefined = this.cache.get(type);
    if (!formGroup) {
      switch (type) {
        case FormGroupToPersist.ListeDossier:
          formGroup = new FormGroup<IRechercheDossier>({
            numDossier: new FormControl<string[]>([], { nonNullable: true }),
            departement: new FormControl<EnumDepartement[]>([], { nonNullable: true }),
            statut: new FormControl<EnumStatutDossier[]>([], { nonNullable: true }),
            typeDemande: new FormControl<EnumTypeDemande[]>([], { nonNullable: true }),
            client: new FormControl<string[]>([], { nonNullable: true }),
            numVolVoyage: new FormControl<string[]>([], { nonNullable: true }),
            numGroupage: new FormControl<string[]>([], { nonNullable: true }),
            agent: new FormControl<string[]>([], { nonNullable: true }),
            transporteur: new FormControl<TransporteurLight[]>([], { nonNullable: true }),
            numDeclaration: new FormControl<string[]>([], { nonNullable: true }),
            operateur: new FormControl<Operateur[]>([], { nonNullable: true }),
            provenance: new FormControl<string[]>([], { nonNullable: true }),
            numNatira: new FormControl<string[]>([], { nonNullable: true }),
            numConteneur: new FormControl<string[]>([], { nonNullable: true }),
            etatVoyage: new FormControl<EnumEtatVoyage[]>([], { nonNullable: true }),
            blLta: new FormControl<string[]>([], { nonNullable: true }),
            referenceTiers: new FormControl<string[]>([], { nonNullable: true }),
            nomNavire: new FormControl<string[]>([], { nonNullable: true }),
            numFacturesFournisseur: new FormControl<string[]>([], { nonNullable: true }),

          });
          break;
        case FormGroupToPersist.ListeDevis:
          formGroup = new FormGroup<IRechercheDevis>({
            numDevis: new FormControl<string | null>(null, [Validators.maxLength(16)]),
            dateEmission: new FormControl<string | null>(null),
            departement: new FormControl<EnumDepartement | null>(null),
            statut: new FormControl<EnumStatutDevis | null>(null),
            agent: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            typeDemande: new FormControl<EnumTypeDemande | null>(null),
            nomClient: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            accepte: new FormControl<boolean | null>(null),
          });
          break;
        case FormGroupToPersist.ListeClient:
          formGroup = new FormGroup<IRechercheClient>({
            nom: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            nomContact: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            prenomContact: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            emailContact: new FormControl<string | null>(null, [Validators.maxLength(255)]),
            compteTier: new FormControl<string | null>(null, [Validators.maxLength(11)]),
          });
          break;
        case FormGroupToPersist.ListeAgent:
          formGroup = new FormGroup<IRechercheAgent>({
            nom: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            nomContact: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            prenomContact: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            emailContact: new FormControl<string | null>(null, [Validators.maxLength(255)]),
          });
          break;
        case FormGroupToPersist.ListeDeclarant:
          formGroup = new FormGroup<IRechercheDeclarant>({
            nom: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            nomContact: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            prenomContact: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            emailContact: new FormControl<string | null>(null, [Validators.maxLength(255)]),
          });
          break;
        case FormGroupToPersist.ListeTransporteur:
          formGroup = new FormGroup<IRechercheTransporteur>({
            nom: new FormControl<string | null>(null),
            type: new FormControl<EnumTypeTransporteur | null>(null),
          });
          break;
        case FormGroupToPersist.ListeRubriqueFacturation:
          formGroup = new FormGroup<IRechercheLigne>({
            libelle: new FormControl<string | null>(null, [Validators.maxLength(255)]),
            categorie: new FormControl<EnumCategorieFacturation | null>(null),
          });
          break;
        case FormGroupToPersist.ListeDemandeDevisTsn:
          formGroup = new FormGroup<IRechercheDemandeDevis>({
            client: new FormControl<string | null>(null, []),
            nature: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            departement: new FormControl<EnumDepartement | null>(null, [Validators.maxLength(128)]),
            origine: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            commentaire: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            createUser: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            destination: new FormControl<string | null>(null, [Validators.maxLength(128)]),
            provenance: new FormControl<string | null>(null, [Validators.maxLength(128)]),
          });
          break;
        case FormGroupToPersist.ListeEvenement:
          formGroup = new FormGroup<IFormRecherhceEvenement>({
            titre: new FormControl<string | null>(null),
            message: new FormControl<string | null>(null),
            type: new FormControl<EnumTypeEvenement | null>(null),
            createUser: new FormControl<string | null>(null),
            departement: new FormControl<EnumDepartement | null>(null),
          });
          break;
        case FormGroupToPersist.ListeDemandeDossier:
          formGroup = new FormGroup<IRechercheDemandeDossier>({
            departement: new FormControl<EnumDepartement | null>(null, []),
            typeDemande: new FormControl<EnumTypeDemande | null>(null, []),
            client: new FormControl<string | null>(null, []),
            agent: new FormControl<string | null>(null, []),
            statut: new FormControl<EnumStatutDemandeDossier | null>(null, []),
          });
      }

      this.cache.set(type, formGroup);
    }
    return formGroup;
  }

  clear() {
    this.cache.clear();
  }

}

export enum FormGroupToPersist {
  ListeDossier,
  ListeDevis,
  ListeClient,
  ListeAgent,
  ListeDeclarant,
  ListeTransporteur,
  ListeRubriqueFacturation,
  ListeDemandeDevisTsn,
  ListeEvenement,
  ListeDemandeDossier,
}
