<aside #offcanvas class="customizer d-none d-lg-none d-xl-block" [ngClass]="{open: isOpen}">
  <a class="customizer-close" (click)="closeCustomizer()">
    <i class="ft-x font-medium-3"></i></a>
  <div [perfectScrollbar] class="customizer-content p-1 pt-3 ps-container ps-theme-dark">

    <ng-container *ngIf="currentTemplate">
      <ng-container *ngTemplateOutlet="currentTemplate">
      </ng-container>
    </ng-container>

  </div>
</aside>

