import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { KeycloakService } from 'keycloak-angular';
import { Location } from '@angular/common';
import { BaseService } from '../base.service';
import { EnumRoleUtilisateur } from '../../../api/model/utilisateurs/enumRoleUtilisateur';
import { FormGroupPersistance } from '../form-group-persistance';

/** interface de UserService */
export interface IUserService {
  /**
   * @returns les rôles de l'utilisateur
   */
  getUserRoles(): string[];

  /**
   * Charge le profil keycloak de l'utilisateur.
   * @returns une promesse du profil utilisateur
   */
  loadUserProfile(): Promise<Keycloak.KeycloakProfile>;

  /**
   * @returns une promesse de true si l'utlisateur est connecte
   */
  isLoggedIn(): Promise<boolean>;

  /**
   * @returns une promesse avec le token
   */
  getToken(): Promise<string>;

  /**
   * Login de l'utilisateur.
   * @return une promise
   */
  login(): Promise<void>;

  /** Logout de l'utilisateur. */
  logout(): void;

  /** Reset le profil keycloak. */
  resetProfil(): void;

  /**
   * si modification d'un annuaire
   */
  hasRoleAnnuaireModification(): boolean;

  /**
   * si modification de param
   */
  hasRoleParamModification(): boolean;

  /**
   * si modification et création de devis
   */
  hasRoleDevisModification(): boolean;

  /**
   * si role modification dossier
   */
  hasRoleDossierModification(): boolean;

  /**
   * si role client
   */
  hasRoleClient(): boolean;

  /**
   * Is opérateur TSN
   */
  isTsn(): boolean;

  /**
   * has role Agent
   */
  hasRoleAgent(): boolean;

  /**
   * has role prospect
   */
  hasRoleProspect(): boolean;
}

/**
 * Service des utilisateurs.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService implements IUserService {
  /** Profil keycloak. */
  profilKeycloak?: Keycloak.KeycloakProfile;
  private ALL_TSN: string[] = [
    EnumRoleUtilisateur.GestionUtilisateur.toString(),
    EnumRoleUtilisateur.ConsultationAnnuaire.toString(),
    EnumRoleUtilisateur.ModificationAnnuaire.toString(),
    EnumRoleUtilisateur.ConsultationParam.toString(),
    EnumRoleUtilisateur.ModificationParam.toString(),
    EnumRoleUtilisateur.ConsultationDevis.toString(),
    EnumRoleUtilisateur.ModificationDevis.toString(),
    EnumRoleUtilisateur.ConsultationDossier.toString(),
    EnumRoleUtilisateur.ModificationDossier.toString(),
    EnumRoleUtilisateur.Compta.toString()];

  constructor(protected override readonly logger: NGXLogger, private readonly keycloak: KeycloakService, private readonly locationServ: Location, private readonly fgp: FormGroupPersistance) {
    super(logger);
  }

  getUserRoles(): string[] {
    return this.keycloak.getUserRoles();
  }

  async loadUserProfile(): Promise<Keycloak.KeycloakProfile> {
    this.profilKeycloak = await this.keycloak.loadUserProfile();
    return Promise.resolve(this.profilKeycloak);
  }

  isLoggedIn(): Promise<boolean> {
    return this.keycloak.isLoggedIn();
  }

  getToken(): Promise<string> {
    return this.keycloak.getToken();
  }

  login(url?: string): Promise<void> {
    return this.keycloak.login({
      redirectUri: url ? `${window.location.origin}${this.locationServ.prepareExternalUrl(url)}` : undefined,
    });
  }

  logout(): void {
    this.fgp.clear();
    this.profilKeycloak = undefined;
    this.keycloak.logout(`${window.location.origin}${this.locationServ.prepareExternalUrl('/')}`);
  }

  public resetProfil(): void {
    this.profilKeycloak = undefined;
  }

  hasRoleAnnuaireModification(): boolean {
    return this.getUserRoles().includes(EnumRoleUtilisateur.ModificationAnnuaire);
  }

  hasRoleParamModification(): boolean {
    return this.getUserRoles().includes(EnumRoleUtilisateur.ModificationParam);
  }

  hasRoleDevisModification(): boolean {
    return this.getUserRoles().includes(EnumRoleUtilisateur.ModificationDevis);
  }

  hasRoleDossierModification(): boolean {
    return this.getUserRoles().includes(EnumRoleUtilisateur.ModificationDossier);
  }

  hasRoleDossierConsultation(): any {
    return this.getUserRoles().includes(EnumRoleUtilisateur.ConsultationDossier);

  }

  hasRoleCompta(): any {
    return this.getUserRoles().includes(EnumRoleUtilisateur.Compta);

  }

  hasRoleClient(): boolean {
    return this.getUserRoles().includes(EnumRoleUtilisateur.Client);
  }

  hasRoleAgent(): boolean {
    return this.getUserRoles().includes(EnumRoleUtilisateur.Agent);
  }

  isTsn(): boolean {
    return this.getUserRoles().some(roleUser => {
      return this.ALL_TSN.includes(roleUser);
    });
  }

  hasRoleProspect(): boolean {
    return this.getUserRoles().includes(EnumRoleUtilisateur.Prospect);
  }
}
