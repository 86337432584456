import {Injectable, TemplateRef} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  // Observable
  private toggleSidebar = new Subject<boolean>(); // small screen
  toggleSidebar$ = this.toggleSidebar.asObservable();
  private overlaySidebarToggle = new Subject<boolean>();
  overlaySidebarToggle$ = this.overlaySidebarToggle.asObservable();
  private toggleNotiSidebar = new Subject<boolean>();
  toggleNotiSidebar$ = this.toggleNotiSidebar.asObservable();
  private isOpenOffCanvas = new Subject<TemplateRef<any> | null>();
  toggleOffCanvas$ = this.isOpenOffCanvas.asObservable();

  toggleSidebarSmallScreen(toggle: boolean) {
    this.toggleSidebar.next(toggle);
  }

  overlaySidebartoggle(toggle: boolean) {
    this.overlaySidebarToggle.next(toggle);
  }

  toggleNotificationSidebar(toggle: boolean) {
    this.toggleNotiSidebar.next(toggle);
  }

  /**
   * Affiche le modèle fourni en paramètre
   * @param template le modèle à afficher
   */
  toggleOffCanvas(template: TemplateRef<any>): void {
    this.isOpenOffCanvas.next(template);
  }

  /**
   * ferme le canvas
   */
  closeOffCanvas(): void {
    this.isOpenOffCanvas.next(null);
  }

}
