import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarLinkDirective } from './sidebar-link.directive';

@Directive({
  selector: '[appSidebarDropdown]',
})
export class SidebarDropdownDirective {
  protected navlinks: Array<SidebarLinkDirective> = [];

  constructor(private router: Router) {}
}
