import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BootstrapVersion, NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from './components/base/base-component';
import { LaddaModule } from 'angular2-ladda';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ColonneActionsComponent } from './components/colonne-actions/colonne-actions.component';
import { ErrorPageComponent } from './components/error/error-page.component';
import { AjaxLoaderComponent } from './components/ajax-loader/ajax-loader.component';
import { MessageErreurComponent } from './components/message-erreur/message-erreur.component';
import { BtnRetourComponent } from './components/btn-retour/btn-retour.component';
import { FormulaireGeneriqueComponent } from './components/formulaire-generique/formulaire-generique.component';
import { TitrePageComponent } from './components/titre-page/titre-page.component';
import { CustomErrors } from './classes/custom-errors';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AccordionComponent } from './components/accordion/accordion.component';
import { HtmlEditorComponent } from './components/tiny-mce-editor/html-editor.component';
import { NgbdSortableHeaderDirective } from './directive/sortable.directive';
import { ListeGeneriqueComponent } from './components/liste-generique/liste-generique.component';
import { FileUploadModule } from 'ng2-file-upload';

import { BtnSubmitComponent } from './components/btn-submit/btn-submit.component';
import { ErreurOrLoadComponent } from './components/erreur-or-load/erreur-or-load.component';
import { ImageBlobComponent } from './components/image-blob/image-blob.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { ListeComponent } from './components/liste/liste.component';
import { BlobErrorHttpInterceptor } from './interceptors/BlobErrorHttpInterceptor';
import { ColonneActionsFunComponent } from './components/colonne-actions-fun/colonne-actions-fun.component';
import { HtmlToPlainTextPipe } from './pipe/html-to-plain-text.pipe';
import { NextButtonComponent } from './components/next-button/next-button.component';
import { LoadedDirective } from './directive/loaded.directive';
import { environment } from '../../environments/environment.local-dev';
import { ListePagineeGeneriqueComponent } from './components/liste-paginee-generique/liste-paginee-generique.component';
import { SortableHeaderComponent } from './components/sortable-header/sortable-header.component';
import { HistoriqueComponent } from './components/historique/historique.component';
import { AuditListeComponent } from './components/audit-liste/audit-liste.component';
import { PiecesJointesGroupageComponent } from './components/pieces-jointes-groupage/pieces-jointes-groupage.component';
import { DemandeDossierComponent } from './components/demande-dossier/demande-dossier.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    BaseComponent,
    ColonneActionsComponent,
    ErrorPageComponent,
    AjaxLoaderComponent,
    BtnRetourComponent,
    MessageErreurComponent,
    FormulaireGeneriqueComponent,
    TitrePageComponent,
    AccordionComponent,
    HtmlEditorComponent,
    NgbdSortableHeaderDirective,
    ListeGeneriqueComponent,
    ListePagineeGeneriqueComponent,
    HistoriqueComponent,
    AuditListeComponent,
    SortableHeaderComponent,
    BtnSubmitComponent,
    ErreurOrLoadComponent,
    ImageBlobComponent,
    UploaderComponent,
    ListeComponent,
    ColonneActionsFunComponent,
    HtmlToPlainTextPipe,
    NextButtonComponent,
    LoadedDirective,
    PiecesJointesGroupageComponent, DemandeDossierComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,

    SweetAlert2Module,
    NgbModule,
    // https://github.com/moff/angular2-ladda
    LaddaModule.forRoot({
      style: 'expand-left',
    }),
    FormsModule,
    ReactiveFormsModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    NgBootstrapFormValidationModule.forRoot({
      bootstrapVersion: BootstrapVersion.Four,
      customErrorMessages: CustomErrors.customErrors,
    }),
    AngularEditorModule,
    NgbAccordionModule,
    FileUploadModule,
    NgSelectModule,
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
  ],
  exports: [
    ColonneActionsComponent,
    AjaxLoaderComponent,
    MessageErreurComponent,
    BtnRetourComponent,
    TitrePageComponent,
    FormulaireGeneriqueComponent,
    HistoriqueComponent,
    AuditListeComponent,
    AccordionComponent,
    HtmlEditorComponent,
    NgbdSortableHeaderDirective,
    ListeGeneriqueComponent,
    ListePagineeGeneriqueComponent,
    SortableHeaderComponent,
    BtnSubmitComponent,
    ErreurOrLoadComponent,
    ImageBlobComponent,
    UploaderComponent,
    ColonneActionsFunComponent,
    HtmlToPlainTextPipe,
    NextButtonComponent,
    LoadedDirective,
    ListeComponent,
    PiecesJointesGroupageComponent, DemandeDossierComponent,
  ],
})
export class CoreModule {
}
