/** interface de UtilisateursApiService */
import { BaseService } from '../base.service';
import { Injectable } from '@angular/core';
import { UtilisateursApiService } from '../../../api/api/utilisateurs/utilisateursApi.service';
import { NGXLogger } from 'ngx-logger';
import { ErreurService } from '../erreur.service';
import { Pageable } from '../../classes/pageable';
import { Observable, tap } from 'rxjs';
import { UtilisateurPaged } from '../../../api/model/utilisateurs/utilisateurPaged';
import { Utilisateur } from '../../../api/model/utilisateurs/utilisateur';
import { catchError } from 'rxjs/operators';
import { Audit } from '../../../api/model/audit/audit';
import { AuditPaged } from '../../../api/model/audit/auditPaged';
import { RechercheUtilisateur } from '../../../api/model/utilisateurs/rechercheUtilisateur';

export interface IUtilisateursService {
  /**
   * liste des utilisateurs
   * @param page la pagination
   * @param rechercheUtilisateur recherche
   */
  listeUtilisateur(page: Pageable, rechercheUtilisateur: RechercheUtilisateur): Observable<UtilisateurPaged>;

  /**
   * récupération d'un utilisateur
   * @param id l'id de l'utilisateur
   */
  retrieve(id: number): Observable<Utilisateur>;

  /**
   * création d'un utilisateur
   * @param utilisateur l'utilisateur à créer
   */
  createUtilisateur(utilisateur: Utilisateur): Observable<Utilisateur>;

  /**
   * modification d'un utilisateur
   * @param utilisateur l'utilisateur à modifier
   */
  updateUtilisateur(utilisateur: Utilisateur): Observable<Utilisateur>;

  /**
   * récupération de l'audit d'un utilisateur
   * @param utilisateur l'utilisateur
   * @param filtre le filtre de recherche
   * @param page la pagination
   */
  audit(utilisateur: Utilisateur, filtre: Audit, page: Pageable): Observable<AuditPaged>;

  /**
   * Met ajour le token de notifications d'un utilisateur
   * @param token token
   */
  updateUtilisateurNotificationToken(token: string): Observable<void>;

}

@Injectable({
  providedIn: 'root',
})
export class UtilisateursService extends BaseService implements IUtilisateursService {

  private readonly _idNonPresent: string = 'ID non présent';
  private readonly _idPresent: string = 'ID présent';

  constructor(
    protected readonly utilisateurService: UtilisateursApiService,
    protected override readonly logger: NGXLogger,
    protected readonly erreurService: ErreurService,
  ) {
    super(logger);
  }

  listeUtilisateur(page: Pageable, rechercheUtilisateur: RechercheUtilisateur): Observable<UtilisateurPaged> {
    return this.utilisateurService.listeUtilisateurs(page.page, page.size, rechercheUtilisateur, page.getSort()).pipe(
      tap(this.logResult('Liste des utilisateurs')),
      catchError((err) => this.erreurService.handleError(err)),
    );
  }

  retrieve(id: number): Observable<Utilisateur> {
    return this.utilisateurService.utilisateur(id).pipe(
      tap(this.logResult('Récupération d\'un utilisateur')),
      catchError((err) => this.erreurService.handleError(err)),
    );
  }

  createUtilisateur(utilisateur: Utilisateur): Observable<Utilisateur> {
    if (utilisateur.id) {
      throw new Error(this._idPresent);
    }
    return this.utilisateurService.createUtilisateur(utilisateur).pipe(
      tap(this.logResult('Création d\'un utilisateur')),
      catchError((err) => this.erreurService.handleError(err)),
    );
  }

  updateUtilisateur(utilisateur: Utilisateur): Observable<Utilisateur> {
    if (!utilisateur.id) {
      throw new Error(this._idNonPresent);
    }
    return this.utilisateurService.editUtilisateur(utilisateur).pipe(
      tap(this.logResult('Modification d\'un utilisateur')),
      catchError((err) => this.erreurService.handleError(err)),
    );
  }

  audit(utilisateur: Utilisateur, filtre: Audit, page: Pageable): Observable<AuditPaged> {
    if (!utilisateur.id) {
      throw new Error(this._idNonPresent);
    }
    return this.utilisateurService.auditUtilisateurs(utilisateur.id, page.page, page.size, filtre, page.getSort()).pipe(
      tap(this.logResult('Audit d\'un utilisateur')),
      catchError((err) => this.erreurService.handleError(err)),
    );
  }

  updateUtilisateurNotificationToken(token: string): Observable<void> {
    return this.utilisateurService.updateUtilisateurNotificationToken(token).pipe(
      tap(this.logResult('Maj du token utilisateur')),
      catchError((err) => this.erreurService.handleError(err)),
    );
  }
}
