import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ROUTES } from './vertical-menu-routes.config';

import { Router } from '@angular/router';
import { customAnimations } from '../animations/custom-animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { RouteInfo } from './vertical-menu.metadata';
import { UserService } from '../../services/param/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout: NodeJS.Timeout;
  protected innerWidth: any;

  constructor(
    private readonly router: Router,
    private readonly layoutService: LayoutService,
    private readonly configService: ConfigService,
    private readonly cdr: ChangeDetectorRef,
    private readonly deviceService: DeviceDetectorService,
    private readonly userService: UserService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  ngOnInit() {
    this.menuItems = ROUTES;
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      (collapse) => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        this.innerWidth = event.target.innerWidth;
        this.loadLayout();
      }).bind(this),
      500
    );
  }

  loadLayout() {
    this.menuItems = ROUTES;

    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    this.collapseSidebar = !!this.config.layout.sidebar.collapsed;
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function() {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    this.perfectScrollbarEnable = !(isMobile || isTablet);
  }

  /**
   * Le menu est-il affichable ?
   *
   * @param routeInfo - l'élément du menu
   */
  isAffichable(routeInfo: RouteInfo): boolean {
    // pas de rôles => c'est affichable
    if (!routeInfo.roles) {
      return true;
    }
    //on regarde s'il a au moins 1 rôle
    return routeInfo.roles.some((role) =>
      this.userService.getUserRoles().includes(role)
    );
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }
}
