<div>
    <div class="main-panel">
        <!-- BEGIN : Main Content-->
        <div class="main-content">
            <div class="content-wrapper p-0">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <!-- FOOTER -->
    <app-footer [textClass]="'text-black'"></app-footer>
</div>
