import { ErrorMessage } from 'ng-bootstrap-form-validation';
import { formatDate } from '@angular/common';

/**
 * Messages d'erreurs version française pour le module ng-bootstrap-form-validation.
 */
export class CustomErrors {
  /** La liste des messages d'erreur. */
  public static customErrors: ErrorMessage[] = [
    {
      error: 'required',
      format: CustomErrors.requiredFormat,
    },
    {
      error: 'email',
      format: CustomErrors.emailFormat,
    },
    {
      error: 'minlength',
      format: CustomErrors.minlengthFormat,
    },
    {
      error: 'maxlength',
      format: CustomErrors.maxlengthFormat,
    },
    {
      error: 'pattern',
      format: CustomErrors.patternFormat,
    },
    {
      error: 'min',
      format: CustomErrors.min,
    },
    {
      error: 'max',
      format: CustomErrors.max,
    },
    {
      error: 'customMin',
      format: CustomErrors.customMinFormat,
    },
    {
      error: 'customMax',
      format: CustomErrors.customMaxFormat,
    },
    {
      error: 'dateMinValue',
      format: CustomErrors.dateMinFormat,
    },
    {
      error: 'dateMaxValue',
      format: CustomErrors.dateMaxFormat,
    },
  ];

  private static emailFormat(label?: string, error?: any): string {
    return `L'adresse mail n'est pas correcte`;
  }

  private static requiredFormat(label?: string): string {
    return `Le champ est obligatoire`;
  }

  private static minlengthFormat(label?: string, error?: any): string {
    return `Le champ doit faire au moins ${error.requiredLength} caractères`;
  }

  private static maxlengthFormat(label?: string, error?: any): string {
    return `Le champ doit faire au plus ${error.requiredLength} caractères`;
  }

  private static patternFormat(label?: string): string {
    return 'Le champ est invalide';
  }

  private static dateMinFormat(label?: string, error?: any): string {
    return `La date doit être au minimum ${formatDate(error.minDate, 'shortDate', 'fr-FR')}`;
  }

  private static dateMaxFormat(label?: string, error?: any): string {
    return `La date doit être au maximum ${formatDate(error.maxDate, 'shortDate', 'fr-FR')}`;
  }

  private static min(label: string, value: { min: number; actual: string }): string {
    return 'Le champ doit être au minimum égal à ' + value.min;
  }

  private static max(label: string, value: { max: number; actual: string }): string {
    return 'Le champ doit être au maximum égal à ' + value.max;
  }

  private static customMinFormat(label?: string, error?: any): string {
    return `Le champ doit être au minimum égal à  ${error.requiredValue}`;
  }

  private static customMaxFormat(label?: string, error?: any): string {
    return `Le champ doit être au maximum égal à ${error.requiredValue}`;
  }
}
