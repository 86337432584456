<!-- Sidebar Header starts -->
<div *ngIf="config?.layout.menuPosition === 'Side'" class='sidebar-header'>
  <div class='logo clearfix'>
    <a [routerLink]="['/']" class='logo-text float-left'>
      <div class='logo-img'>
        <img [src]='logoUrl' alt='Logo menu' height='30' width='30' />
      </div>
      <span class='text align-middle'>GesCoTS</span>
    </a>
    <a (click)='toggleSidebar()' class='nav-toggle d-none d-lg-none d-xl-block' href='javascript:'
       id='sidebarToggle'>
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class='toggle-icon'></i>
    </a>
    <a (click)='CloseSidebar()' class='nav-close d-block d-lg-block d-xl-none' href='javascript:' id='sidebarClose'>
      <i class='ft-x'></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div [disabled]='!perfectScrollbarEnable' [perfectScrollbar] class='sidebar-content main-menu-content'>
  <div class='nav-container'>
    <ul appSidebarDropdown class='navigation'>
      <!-- First level menu -->
      <ng-container *ngFor='let menuItem of menuItems'>
        <li *ngIf='isAffichable(menuItem)' [hasSub]="!!menuItem.class.includes('has-sub')" [level]='level + 1'
            [ngClass]="{'has-sub': !!menuItem.class.includes('has-sub')}" [parent]='menuItem.title'
            [path]='menuItem.path'
            [routerLinkActiveOptions]='{exact: false}'
            [routerLinkActive]="menuItem.submenu.length !== 0 ? 'open' : 'active'" appSidebarlink>
          <a *ngIf='menuItem.submenu.length > 0 && !menuItem.isExternalLink' appSidebarAnchorToggle>
            <i [ngClass]='[menuItem.icon]'></i>
            <span class='menu-title'>{{menuItem.title  }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge !== '' " [ngClass]='[menuItem.badgeClass]'>{{menuItem.badge}}</span>
          </a>
          <a *ngIf='menuItem.submenu.length === 0 && !menuItem.isExternalLink' appSidebarAnchorToggle
             routerLink='{{menuItem.path}}'>
            <i [ngClass]='[menuItem.icon]'></i>
            <span class='menu-title'>{{menuItem.title }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge !== '' " [ngClass]='[menuItem.badgeClass]'>{{menuItem.badge}}</span>
          </a>
          <a *ngIf='menuItem.isExternalLink' [href]='[menuItem.path]' target='_blank'>
            <i [ngClass]='[menuItem.icon]'></i>
            <span class='menu-title'>{{menuItem.title }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge !== '' " [ngClass]='[menuItem.badgeClass]'>{{menuItem.badge}}</span>
          </a>
          <!-- Second level menu -->
          <ul *ngIf='menuItem.submenu.length > 0 ' appSidebarDropdown class='menu-content'>
            <ng-container *ngFor='let menuSubItem of menuItem.submenu'>
              <li *ngIf='isAffichable(menuSubItem)' [hasSub]="!!menuSubItem.class.includes('has-sub')" [level]='level + 2'
                  [ngClass]="{'has-sub': !!menuSubItem.class.includes('has-sub')}"
                  [parent]='menuItem.title' [path]='menuSubItem.path'
                  [routerLinkActive]="menuSubItem.submenu.length !== 0 ? 'open' : 'active'"
                  appSidebarlink>
                <a *ngIf='menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink' appSidebarAnchorToggle>
                  <i [ngClass]='[menuSubItem.icon]'></i>
                  <span class='menu-title'>{{menuSubItem.title }}</span>
                  <span *ngIf="menuSubItem.badge && menuSubItem.badge !== '' "
                        [ngClass]='[menuSubItem.badgeClass]'>{{menuSubItem.badge}}</span>
                </a>
                <a *ngIf='menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink' appSidebarAnchorToggle
                   routerLink='{{menuSubItem.path}}'>
                  <i [ngClass]='[menuSubItem.icon]'></i>
                  <span class='menu-title'>{{menuSubItem.title  }}</span>
                  <span *ngIf="menuSubItem.badge && menuSubItem.badge !== '' "
                        [ngClass]='[menuSubItem.badgeClass]'>{{menuSubItem.badge}}</span>
                </a>
                <a *ngIf='menuSubItem.isExternalLink' routerLink='{{menuSubItem.path}}' target='_blank'>
                  <i [ngClass]='[menuSubItem.icon]'></i>
                  <span class='menu-title'>{{menuSubItem.title  }}</span>
                  <span *ngIf="menuSubItem.badge && menuSubItem.badge !== '' "
                        [ngClass]='[menuSubItem.badgeClass]'>{{menuSubItem.badge}}</span>
                </a>
                <!-- Third level menu -->
                <ul *ngIf='menuSubItem.submenu.length > 0' appSidebarDropdown class='menu-content'>
                  <ng-container *ngFor='let menuSubsubItem of menuSubItem.submenu'>
                    <li *ngIf='isAffichable(menuSubsubItem)' [hasSub]='false' [level]='level + 3'
                        [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''"
                        [parent]='menuItem.title' [path]='menuSubsubItem.path' [routerLinkActiveOptions]='{exact: false}'
                        appSidebarlink
                        routerLinkActive='active'>
                      <a *ngIf='!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock' appSidebarAnchorToggle
                         routerLink='{{menuSubsubItem.path}}'>
                        <i [ngClass]='[menuSubsubItem.icon]'></i>
                        <span class='menu-title'>{{menuSubsubItem.title  }}</span>
                        <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge !== '' "
                              [ngClass]='[menuSubsubItem.badgeClass]'>{{menuSubsubItem.badge}}</span>
                      </a>
                      <ng-template #externalSubSubLinkBlock>
                        <a routerLink='{{menuSubsubItem.path}}' target='_blank'>
                          <i [ngClass]='[menuSubsubItem.icon]'></i>
                          <span class='menu-title'>{{menuSubsubItem.title  }}</span>
                          <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge !== '' "
                                [ngClass]='[menuSubsubItem.badgeClass]'>{{menuSubsubItem.badge}}</span>
                        </a>
                      </ng-template>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
