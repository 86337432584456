import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import * as Sentry from '@sentry/angular';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './core/shared/shared.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './core/layouts/content/content-layout.component';
import { FullLayoutComponent } from './core/layouts/full/full-layout.component';

import { WINDOW_PROVIDERS } from './core/shared/services/window.service';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { ApiModule } from './api/api.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment.local-dev';
import { ToastrModule } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { initializeApp } from 'firebase/app';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

// on importe le français pour le formatage des dates & co
// https://angular.io/guide/i18n
registerLocaleData(localeFr, 'fr');
initializeApp(environment.firebaseConfig);

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakServer,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId,

      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        pkceMethod: 'S256',
        useNonce: true,
        enableLogging: true,
        checkLoginIframe: false,
      },
      bearerExcludedUrls: ['/assets'],
    });
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    HttpClientModule,

    CoreModule,

    // https://angular.io/guide/singleton-services
    // il ne faut importer ce service qu'ici sinon les services ne sont pas des singletons !
    ApiModule,
    NgxSpinnerModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot({
      provideSwal: () =>
        import('sweetalert2').then(({ default: swal }) =>
          swal.mixin({
            confirmButtonText: `<i class="fa fa-check"></i> Confirmer`,
            cancelButtonText: `<i class="fa fa-times"></i> Annuler`,
            customClass: {
              confirmButton: 'btn btn-success round m-1',
              cancelButton: 'btn btn-secondary round m-1',
            },
            buttonsStyling: false,
          }),
        ),
    }),

    //keycloak
    KeycloakAngularModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    WINDOW_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
  }
}
