import { Injectable } from '@angular/core';
import { Observer } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})

/**
 * Service de base.
 */
export abstract class BaseService {
  protected constructor(protected readonly logger: NGXLogger) {}

  public parseFilenameFromContentDisposition(contentDisposition: string | null): string {
    if (!contentDisposition) {
      return '';
    }
    const matches = /filename="(.*)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : '';
  }

  /**
   * Télécharge une pièce jointe
   * @param file le blob contenant la pièce jointe
   * @param fileName le nom du fichier
   * @param mimeType le type du fichier, optionel
   */
  public dowloadFile(file: Blob | null, fileName: string, mimeType?: string): void {
    if (!file) {
      throw new Error('Aucun fichier récupéré');
    }
    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api/52687792
    const blob = new Blob([file], { type: mimeType });

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.download = fileName;
    anchor.href = url;
    // this is necessary as link.click() does not work on the latest firefox
    anchor.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      anchor.remove();
    }, 500);
  }

  /**
   * Log le résultat de l'opération en success ou error
   * @param message message à afficher dans le log
   * @protected
   */
  protected logResult<T>(message: string): Observer<T> {
    return {
      next: (x: any) => this.logger.debug('Succeed: ' + message, x),
      error: (err: any) => this.logger.error('Error: ' + message, err),
      complete: () => null,
    };
  }
}
