import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from './core/layouts/full/full-layout.component';
import { PRIVATE_ROUTES } from './core/shared/routes/full-layout.routes';
import { AuthGuard } from './core/guards/auth.guard';
import { ErrorPageComponent } from './core/components/error/error-page.component';
import { Url } from './core/classes/url';
import { ContentLayoutComponent } from './core/layouts/content/content-layout.component';
import { PUBLIC_ROUTES } from './core/shared/routes/content-layout.routes';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: Url.HOME_BASE,
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: PRIVATE_ROUTES,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: PUBLIC_ROUTES,
  },
  {
    path: Url.ERREUR_BASE,
    component: ErrorPageComponent,
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: { erreur: 'Page inexistante' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
