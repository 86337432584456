import { Routes } from '@angular/router';
import { Url } from '../../classes/url';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const PUBLIC_ROUTES: Routes = [
  {
    path: Url.PUBLIC_BASE,
    loadChildren: () => import('../../../modules/inscription/inscription.module').then(m => m.InscriptionModule),
  },
];
