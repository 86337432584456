import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base/base-component';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent extends BaseComponent implements OnInit {
  constructor(private readonly router: Router, private readonly route: ActivatedRoute) {
    super();
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as { erreur: string };
      this.erreur = state?.erreur ?? undefined;
    }
  }

  public ngOnInit(): void {
    if (!this.erreur) {
      //on recherche dans le data du routing
      this.subscription.add(this.route.data.subscribe((v) => (this.erreur = v['erreur'])));
    }
  }
}
