export class Url {
  /* eslint-disable @typescript-eslint/naming-convention */
  public static ERREUR_BASE = 'error';
  public static WARNING = 'warning';
  public static ID_QUERY_PARAM = ':id';

  public static MODIFICATION = 'modification';
  public static AJOUT = 'ajout';

  // Page d'erreur
  public static ERROR_PAGE = `/${Url.ERREUR_BASE}`;
  // home
  public static HOME_BASE = 'accueil';
  // utilisateurs
  public static USERS_BASE = 'users';
  public static UTILISATEURS_BASE = 'utilisateurs';
  public static PROSPECTS_BASE = 'prospects';
  public static PROFILS_BASE = 'profils';
  public static PUBLIC_BASE = 'public';
  public static ESPACE_CLIENT_BASE = 'espace-client';
  public static ESPACE_AGENT_BASE = 'espace-agent';
  public static INSCRIPTION_BASE = 'inscription';
  public static INSCRIPTION_CONFIRMATION_BASE = 'confirmation';
  public static EVENEMENT_BASE = 'evenements';
  public static DEMANDE_DOSSIER_BASE = 'demande-dossier';

  static UTILISATEURS_PAGE = `/${Url.USERS_BASE}/${Url.UTILISATEURS_BASE}`;
  static PROFILS_PAGE = `/${Url.USERS_BASE}/${Url.PROFILS_BASE}`;

  static UTILISATEURS_AJOUT = `/${Url.USERS_BASE}/${Url.UTILISATEURS_BASE}/${Url.AJOUT}`;
  static PROFILS_AJOUT = `/${Url.USERS_BASE}/${Url.PROFILS_BASE}/${Url.AJOUT}`;
  static PROSPECTS_LISTE = `/${Url.USERS_BASE}/${Url.PROSPECTS_BASE}`;

  //contacts
  public static CONTACTS_BASE = 'contacts';
  public static DECLARANTS_BASE = 'declarants';
  public static OPERATEURS_BASE = 'operateurs';
  static DECLARANT_PAGE = `/${Url.CONTACTS_BASE}/${Url.DECLARANTS_BASE}`;
  static DECLARANT_AJOUT = `/${Url.CONTACTS_BASE}/${Url.DECLARANTS_BASE}/${Url.AJOUT}`;

  static OPERATEUR_PAGE = `/${Url.CONTACTS_BASE}/${Url.OPERATEURS_BASE}`;
  static OPERATEUR_AJOUT = `/${Url.CONTACTS_BASE}/${Url.OPERATEURS_BASE}/${Url.AJOUT}`;

  public static AGENTS_BASE = 'agents';
  static AGENT_PAGE = `/${Url.CONTACTS_BASE}/${Url.AGENTS_BASE}`;
  static AGENT_AJOUT = `/${Url.CONTACTS_BASE}/${Url.AGENTS_BASE}/${Url.AJOUT}`;

  public static TRANSPORTEUR_BASE = 'transporteurs';
  static TRANSPORTEUR_PAGE = `/${Url.CONTACTS_BASE}/${Url.TRANSPORTEUR_BASE}`;
  static TRANSPORTEUR_AJOUT = `/${Url.CONTACTS_BASE}/${Url.TRANSPORTEUR_BASE}/${Url.AJOUT}`;

  public static CLIENT_BASE = 'clients';
  static CLIENT_PAGE = `/${Url.CONTACTS_BASE}/${Url.CLIENT_BASE}`;
  static CLIENT_AJOUT = `/${Url.CONTACTS_BASE}/${Url.CLIENT_BASE}/${Url.AJOUT}`;

  //params
  public static PARAMS_BASE = 'parametres';
  public static TVA_BASE = 'tva';
  static TVA_PAGE = `/${Url.PARAMS_BASE}/${Url.TVA_BASE}`;
  static TVA_AJOUT = `/${Url.PARAMS_BASE}/${Url.TVA_BASE}/${Url.AJOUT}`;

  public static GRILLE_BASE = 'grille';
  static GRILLE_PAGE = `/${Url.PARAMS_BASE}/${Url.GRILLE_BASE}`;
  static GRILLE_AJOUT = `/${Url.PARAMS_BASE}/${Url.GRILLE_BASE}/${Url.AJOUT}`;

  public static LIGNE_BASE = 'lignes-facturation';
  static LIGNE_PAGE = `/${Url.PARAMS_BASE}/${Url.LIGNE_BASE}`;
  static LIGNE_AJOUT = `/${Url.PARAMS_BASE}/${Url.LIGNE_BASE}/${Url.AJOUT}`;

  public static LIEU_BASE = 'lieux';
  static LIEU_PAGE = `/${Url.PARAMS_BASE}/${Url.LIEU_BASE}`;
  static LIEU_AJOUT = `/${Url.PARAMS_BASE}/${Url.LIEU_BASE}/${Url.AJOUT}`;

  public static CONSTANTES_BASE = 'Constantes';
  static CONSTANTES_PAGE = `/${Url.PARAMS_BASE}/${Url.CONSTANTES_BASE}`;

  //dossier
  public static COMMERCIAL = 'commercial';
  public static DOSSIER_BASE = 'dossiers';
  public static MARGES_BASE = 'marges';
  public static DEVIS_BASE = 'devis';
  public static DEMANDE_DEVIS_BASE = 'demande-devis';
  public static RAPPORT_ASSURANCE_BASE = 'assurance';
  public static RAPPORT_VALEUR_CAF_BASE = 'valeur-CAF';
  public static RECAP_ACTIVITE_BASE = 'recap-activite';
  public static VOLUMETRIE_BASE = 'volumetrie';
  public static CONVERSION_BASE = 'conversion';
  public static FRET_BASE = 'fret';
  static DEVIS_PAGE = `/${Url.COMMERCIAL}/${Url.DEVIS_BASE}`;
  static DEVIS_AJOUT = `/${Url.COMMERCIAL}/${Url.DEVIS_BASE}/${Url.AJOUT}`;
  static DOSSIER_PAGE = `/${Url.COMMERCIAL}/${Url.DOSSIER_BASE}`;
  static DOSSIER_AJOUT = `/${Url.COMMERCIAL}/${Url.DOSSIER_BASE}/${Url.AJOUT}`;
  static MARGES_PAGE = `/${Url.COMMERCIAL}/${Url.MARGES_BASE}`;
  static RAPPORT_ASSURANCE_PAGE = `/${Url.COMMERCIAL}/${Url.RAPPORT_ASSURANCE_BASE}`;
  static RAPPORT_VALEUR_CAF_PAGE = `/${Url.COMMERCIAL}/${Url.RAPPORT_VALEUR_CAF_BASE}`;
  static RECAP_ACTIVITE_PAGE = `/${Url.COMMERCIAL}/${Url.RECAP_ACTIVITE_BASE}`;
  static RAPPORT_VOLUMETRIE_PAGE = `/${Url.COMMERCIAL}/${Url.VOLUMETRIE_BASE}`;
  static RAPPORT_OPERATEUR_PAGE = `/${Url.COMMERCIAL}/${Url.OPERATEURS_BASE}`;
  static DEMANDE_DEVIS_TSN_PAGE = `/${Url.COMMERCIAL}/${Url.DEMANDE_DEVIS_BASE}`;
  static CONVERSION_DEMANDE_DEVIS_TSN = `/${Url.COMMERCIAL}/${Url.DEMANDE_DEVIS_BASE}/${Url.CONVERSION_BASE}`;
  static DEMANDE_DOSSIER_TO_DOSSIER_PAGE = `/${Url.COMMERCIAL}/${Url.DOSSIER_BASE}/${Url.DEMANDE_DOSSIER_BASE}`;
  static LISTE_DEMANDE_DOSSIER_PAGE = `/${Url.COMMERCIAL}/${Url.DEMANDE_DOSSIER_BASE}`;

// compta
  static COMPTA_BASE = 'compta';
  static EXPORT_FACTURE_BASE = 'export';
  static EXPORT_FACTURE_PAGE = `/${Url.COMPTA_BASE}/${Url.EXPORT_FACTURE_BASE}`;
  static LISTING_ECRITURES_BASE = 'ecritures';
  static LISTING_ECRITURES_PAGE = `/${Url.COMPTA_BASE}/${Url.LISTING_ECRITURES_BASE}`;

  // public

  static INSCRIPTION_PAGE = `/${Url.PUBLIC_BASE}/${Url.INSCRIPTION_BASE}`;

  static DEMANDE_DEVIS_PAGE = `/${Url.ESPACE_CLIENT_BASE}/${Url.DEMANDE_DEVIS_BASE}`;
  static DEMANDE_DEVIS_AJOUT = `/${Url.ESPACE_CLIENT_BASE}/${Url.DEMANDE_DEVIS_BASE}/${Url.AJOUT}`;
  static DEMANDE_DEVIS_CONSULTATION = `/${Url.ESPACE_CLIENT_BASE}/${Url.DEMANDE_DEVIS_BASE}`;
  static DOSSIER_CLIENT_PAGE = `/${Url.ESPACE_CLIENT_BASE}/${Url.DOSSIER_BASE}`;
  static DEVIS_CLIENT_PAGE = `/${Url.ESPACE_CLIENT_BASE}/${Url.DEVIS_BASE}`;


  static LISTE_FRET_PAGE = `/${Url.ESPACE_AGENT_BASE}/${Url.FRET_BASE}`;
  static FRET_CONSULTATION = `/${Url.ESPACE_AGENT_BASE}/${Url.FRET_BASE}`;
  static DOSSIER_AGENT_PAGE = `/${Url.ESPACE_AGENT_BASE}/${Url.DOSSIER_BASE}`;
  static DEMANDE_DOSSIER_PAGE = `/${Url.ESPACE_AGENT_BASE}/${Url.DEMANDE_DOSSIER_BASE}`;


}
