/// <reference types="@angular/localize" />

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment.local-dev";

Sentry.init({
  dsn: "https://e1cff0cde56d45c58e9cfd224b5d4d27@o49545.ingest.sentry.io/4504883784777728",
  release: 'gescots-angular@' + environment.version,
  environment: environment.name,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
