import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from '../environments/environment.local-dev';
import { UtilisateursService } from './core/services/utilisateurs/utilisateurs.service';
import { UserService } from './core/services/param/user.service';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { EvenementService } from './core/services/notification/evenement.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  /** Subscription observable. */
  subscription: Subscription;


  constructor(private readonly router: Router, private utilisateursService: UtilisateursService,
              private evenementService: EvenementService,
              private userService: UserService, private toastr: ToastrService) {
    router.canceledNavigationResolution = 'computed';
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebaseConfig.vapidKey }).then(
      (currentToken) => {
        if (currentToken && this.userService.isTsn()) {
          this.utilisateursService.updateUtilisateurNotificationToken(currentToken).subscribe({
            complete: () => {
              console.log('Token notification soumis au serveur');
            },
            error: err => {
              console.log('Token notification refusé');
            },
          });
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      let activeToast: ActiveToast<any> = this.toastr.warning(payload.notification?.body, payload.notification?.title, { timeOut: 10000 });
      activeToast.onTap.subscribe(value => {
        this.evenementService.redirectToObjectID({

          idObject: (payload.data?.['idObjet']),
          type: (payload.data?.['typeNotification']),
        } as any, 'consultation');
      });
    });
  }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe({ next: () => window.scrollTo(0, 0) });
    this.requestPermission();
    this.listen();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
